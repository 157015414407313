@import 'https://js.arcgis.com/4.32/@arcgis/core/assets/esri/themes/dark/main.css';
@import url("https://js.arcgis.com/calcite-components/3.0.3/calcite.css");
@import url("https://js.arcgis.com/map-components/4.32/arcgis-map-components.css");

#maproot,html,body {
   margin: 0;
   padding: 0;
   height: 100%;
   width: 100%;
}

arcgis-map {
  display: block;
  height: 70vh;
}