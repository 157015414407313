.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

/* Added for Omnimion */
.logo-omm {
  /*svg drawn using https://drawsvg.org/ and coverted to base64 by https://base64.guru/converter/encode/text*/
  /*background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgIDE0OTAgODkwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0IiA+PHJlY3QgaWQ9InN2Z0VkaXRvckJhY2tncm91bmQiIHg9IjAiIHk9IjAiIHdpZHRoPSIxNDkwIiBoZWlnaHQ9Ijg5MCIgc3R5bGU9ImZpbGw6IG5vbmU7IHN0cm9rZTogbm9uZTsiLz48Y2lyY2xlIGlkPSJlM19jaXJjbGUiIGN4PSIyMzkuODM1MDAwIiBjeT0iMTkzLjc1NDAwMCIgc3R5bGU9ImZpbGw6c2t5Ymx1ZTtzdHJva2U6YmxhY2s7c3Ryb2tlLXdpZHRoOjFweCIgcj0iMTMxLjI3MDAwMCIgdHJhbnNmb3JtPSJtYXRyaXgoMi4xODc2NiAwIDAgMi4xODc2NiAtMTE3LjA0IC02Mi4zMTE2KSIvPjxjaXJjbGUgaWQ9ImU0X2NpcmNsZSIgY3g9IjI1MC44NTUwMDAiIGN5PSIxNzkuNzI0MDAwIiBzdHlsZT0iZmlsbDpza3libHVlO3N0cm9rZTpibGFjaztzdHJva2Utd2lkdGg6MXB4IiByPSI5Ni40MzAwMDAiIHRyYW5zZm9ybT0ibWF0cml4KDIuMTg3NjYgMCAwIDIuMTg3NjYgLTExNy4wNCAtNjIuMzExNikiLz48Y2lyY2xlIGlkPSJlNV9jaXJjbGUiIGN4PSIyNjMuODgwMDAwIiBjeT0iMTY1LjcwMTAwMCIgc3R5bGU9ImZpbGw6c2t5Ymx1ZTtzdHJva2U6YmxhY2s7c3Ryb2tlLXdpZHRoOjFweCIgcj0iNjEuMTQwMDAwIiB0cmFuc2Zvcm09Im1hdHJpeCgyLjE4NzY2IDAgMCAyLjE4NzY2IC0xMTcuMDQgLTYyLjMxMTYpIi8+PC9zdmc+);*/
  background-image: url("/public/logo.svg");
  width: 2.5rem;
  height: 2.25rem;
  background-repeat: no-repeat;
  background-size: cover
}

footer .logo-omm{
  margin: 0 auto 10px auto
}

.map-ol {
  height: 65vh;
  width: 100%;
}

.map-ol-sm {
  height: 35vh;
  width: 100%;
}

.station-button{
  list-style: none;
  width: 100%;
}

.imageclassification-task{
  border: 1px solid #676767;
  width: 550px;
  padding: 5px;
  text-align: center;
}

code.gray-90 {
  color: rgb(90, 90, 90);
}

button.full-width {
  width: 100%;
}

#chmi-hovered-feature {
  height: 60px;
}

.searchable-list-item{
  list-style: none;
}

.message-is-even-0 {
  font-size: smaller;
  padding-top: 2px;
  padding-bottom: 2px;
}

.message-is-even-1 {
  font-size: smaller;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: rgb(212, 212, 212);
  text-align: right;
}

.no-list-style {
  list-style: none;
}

.no-padding {
  padding: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.full-width-container{
  max-width: 2096px;
}

#crs-list {
  overflow-y: auto;
  max-height: 350px;
}
